@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.sessions-section {
  &__list-wrapper {
    background-color: light.$surface-primary-default;
    border-radius: corner-radius.$m;
    padding: spacing.$m;

    &:last-child {
      margin-top: spacing.$m;
    }
  }

  &__title {
    margin-bottom: spacing.$s;
  }

  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &__button {
    margin-top: spacing.$xs;
    margin-bottom: -(spacing.$xxs);
  }
}
